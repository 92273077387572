import { useParams } from 'react-router-dom';
import { HeaderAssetDetailsLayout } from '../../common/header';
import { AssetIcon } from '../../common/icons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectMainAccountRests,
	selectSpotAccountsRests,
} from '../../../services/wallet';
import { useGetUserRests } from '../../../data/mutations/wallet';
import { selectIsVerified } from '../../../services/user';
import { selectMarketAssets, setFavouriteAssets } from '../../../services/market';
import { useGetMarketPrices } from '../../../data/mutations/market';
import AssetDetailsTotalCard from './total-card';
import { useEffect, useState } from 'react';
import { TMarketAsset } from '../../../types/types';
import WalletConfirmIdentity from '../../wallet/confirm-identity';
import { AssetDetailsNavigationContainer } from '../../common/navigation';
import AssetDetailsHistory from './history';

const AssetDetailsLayout = () => {
	const { code } = useParams();
	const dispatch = useAppDispatch();

	const isVerified = useAppSelector(selectIsVerified);
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const spotCurrencies = useAppSelector(selectSpotAccountsRests);
	const marketAssets = useAppSelector(selectMarketAssets);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { isLoading: isUserRestsLoading } = useGetUserRests(isVerified);
	const { isLoading: isMarketPriceLoading } = useGetMarketPrices();

	const [asset, setAsset] = useState<TMarketAsset | undefined>(undefined);

	useEffect(() => {
		if (!isUserRestsLoading && !isMarketPriceLoading) {
			setIsLoading(false);
		}
	}, [isUserRestsLoading, isMarketPriceLoading]);

	useEffect(() => {
		if (marketAssets['all']) {
			setAsset(
				marketAssets['all']?.find((asset: TMarketAsset) => asset.code === code)
			);
		}
	}, [marketAssets]);

	const handleFavouriteClick = (isNeedToAdd: boolean) => {
		dispatch(setFavouriteAssets({ asset: asset, isNeedToAdd: isNeedToAdd }));
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderAssetDetailsLayout
					name={asset?.name as string}
					isFavourite={Boolean(
						marketAssets['crypto']?.some((asset) => asset.code === code)
					)}
					handleFavouriteClick={handleFavouriteClick}
				/>

				<div className="walletLayout">
					<AssetIcon
						isLoading={isLoading}
						assetName={code as string}
						group={asset?.group as string}
						className="marketAssetDetailsMainIcon"
						isCustomLoadingClassName="assetIconBigLoading"
					/>
					<AssetDetailsTotalCard
						isLoading={isLoading}
						precision={asset?.precision as number}
						code={code as string}
						isVerified={isVerified}
					/>
					{isVerified ? (
						<>
							<AssetDetailsNavigationContainer
								mainRests={mainCurrencies?.assets}
								spotRests={spotCurrencies?.assets}
								code={code as string}
								isLoading={isLoading}
							/>
						</>
					) : (
						<WalletConfirmIdentity />
					)}

					<AssetDetailsHistory isLoading={isLoading} />
				</div>
			</div>
		</div>
	);
};

export default AssetDetailsLayout;
