import { useTranslation } from 'react-i18next';
import { TWalletModalItem } from '../../../types/types';
import { CurrencyChev, HiddenBalanceIcon, PositiveChev } from '../../common/icons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectBasePrecision,
	selectTotalAmount,
	setIsBalanceHidden,
} from '../../../services/wallet';
import { useEffect, useState } from 'react';
import { formatNumber } from '../../../utils';

interface WalletTotalCardProps {
	isBalanceHidden: boolean;
	isLoading: boolean;
	handleModalOpen: () => void;
	globalAsset: TWalletModalItem;
	isVerified: boolean;
}

const WalletTotalCard = ({
	isBalanceHidden,
	handleModalOpen,
	globalAsset,
	isVerified,
}: WalletTotalCardProps) => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const basePrecision = useAppSelector(selectBasePrecision);
	const totalAmount = useAppSelector(selectTotalAmount);

	const [todayDelta, setTodayDelta] = useState<number>(0);
	const [todayPercent, setTodayPercent] = useState<number>(0);

	useEffect(() => {
		if (isVerified) {
			if (totalAmount?.totalAmount && totalAmount?.estimatedBalance00h) {
				if (totalAmount?.totalAmount > 0 && totalAmount?.estimatedBalance00h) {
					setTodayDelta(
						totalAmount?.totalAmount - totalAmount?.estimatedBalance00h
					);
					setTodayPercent(
						(totalAmount?.totalAmount * 100) /
							totalAmount.estimatedBalance00h -
							100
					);
				}
			}
		}
	}, [totalAmount?.totalAmount, totalAmount?.estimatedBalance00h]);

	return (
		<div className="balanceContainer">
			<div
				onClick={() => dispatch(setIsBalanceHidden(!isBalanceHidden))}
				className="estimatedBalanceContainer"
			>
				<h1 className="estimatedBalanceText">{t('wallet_estimatedBalance')}</h1>
				<HiddenBalanceIcon
					isHidden={isBalanceHidden}
					className="estimatedBalanceIcon"
				/>
			</div>
			{isVerified ? (
				<>
					{totalAmount?.totalAmount && basePrecision != 0 ? (
						<div className="mainBalanceContainer">
							{isBalanceHidden ? (
								<h1 className="mainBalanceText">******</h1>
							) : (
								<h1 className="mainBalanceText">
									<h1 className="mainBalanceText">
										{formatNumber(
											totalAmount.totalAmount,
											basePrecision,
											'.',
											0
										)}
									</h1>
									<h1 className="mainBalanceDecimalText">,</h1>
									<h1 className="mainBalanceDecimalText">
										{formatNumber(
											totalAmount.totalAmount,
											basePrecision,
											'.',
											1
										)}
									</h1>
								</h1>
							)}
							<div
								onClick={handleModalOpen}
								className="mainBalanceSelectContainer"
							>
								<h1 className="currencyText">{globalAsset.code}</h1>
								<CurrencyChev className="currencyTextChev totalAmountTextChev" />
							</div>
						</div>
					) : (
						<div className="mainBalanceContainerLoading"></div>
					)}
				</>
			) : (
				<div className="mainBalanceContainer">
					{isBalanceHidden ? (
						<h1 className="mainBalanceText">******</h1>
					) : (
						<h1 className="mainBalanceText">
							<h1 className="mainBalanceText">
								{formatNumber(0.0, basePrecision, '.', 0)}
							</h1>
							<h1 className="mainBalanceDecimalText">,</h1>
							<h1 className="mainBalanceDecimalText">
								,{formatNumber(0.0, basePrecision, '.', 1)}
							</h1>
						</h1>
					)}
					<div onClick={handleModalOpen} className="mainBalanceSelectContainer">
						<h1 className="currencyText">{globalAsset.code}</h1>
						<CurrencyChev className="currencyTextChev totalAmountTextChev" />
					</div>
				</div>
			)}

			{isVerified && (
				<>
					{!totalAmount?.totalAmount && !totalAmount?.estimatedBalance00h ? (
						<div className="secondaryBalanceContainerLoading"></div>
					) : (
						<div className="secondaryBalanceContainer">
							<h1 className="secondaryBalanceText">{t('wallet_today')}</h1>
							<div className="secondaryBalanceAnalyticsContainer">
								{!isBalanceHidden && (
									<PositiveChev
										isPositive={todayDelta > 0}
										className="analyticsChev"
									/>
								)}
								<h1
									className={
										todayDelta > 0
											? 'secondaryBalanceAnalyticsText'
											: 'secondaryNegativeBalanceAnalyticsText'
									}
								>
									{isBalanceHidden
										? '******'
										: todayPercent.toFixed(2).replace('-', '') +
										  '% ' +
										  '(' +
										  todayDelta.toFixed(basePrecision) +
										  ' ' +
										  globalAsset.code +
										  ')'}
								</h1>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default WalletTotalCard;
