import { useEffect, useMemo, useState } from 'react';
import { MarketAssetItem, SearchAssetItem, WalletItem } from './items';
import { TAsset, TMarketAsset, TMarketTab, TWalletModalItem } from '../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectBasePrecision,
	selectCommissionAccountRests,
	selectGlobalAsset,
	selectIsEmpty,
	selectMainAccountRests,
	selectMarginAccountsRests,
	selectSpotAccountsRests,
	selectWalletConvertAssets,
	setIsEmpty,
} from '../../../services/wallet';
import { useTranslation } from 'react-i18next';
import { FavouriteMarketAssetsEmptyIcon, LeverageIcon, TransferSpotIcon } from '../icons';
import WalletEmptyList from '../../wallet/empty-wallet';
import { selectMarketAssets, setFavouriteAssets } from '../../../services/market';

interface WalletItemListProps {
	isLowBalanceHidden: boolean;
	isLoading?: boolean;
	isBalanceHidden: boolean;
	globalAsset: TWalletModalItem;
	searchValue?: string;
	isWalletSearchModal?: boolean;
	isWalletConvertModal?: boolean;
	handleSelectedAmountChange?: (i: TAsset, isSelected: boolean, idx: number) => void;
	selected?: boolean[];
}

const WalletItemList = ({
	isLowBalanceHidden,
	isLoading,
	globalAsset,
	isBalanceHidden,
	searchValue,
	isWalletSearchModal,
	isWalletConvertModal,
	handleSelectedAmountChange,
	selected,
}: WalletItemListProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const commissionCurrencies = useAppSelector(selectCommissionAccountRests);
	const spotCurrencies = useAppSelector(selectSpotAccountsRests);
	const marginCurrencies = useAppSelector(selectMarginAccountsRests);
	const isEmpty = useAppSelector(selectIsEmpty);

	const walletConvertAssets = useAppSelector(selectWalletConvertAssets);

	const minHideValue = mainCurrencies?.baseLowBalance as number;

	function filterCurrenciesByMinHideValue(
		currencies: TAsset[] | undefined,
		isLowBalanceHidden: boolean,
		searchValue: string | undefined
	) {
		if (currencies) {
			if (searchValue) {
				return currencies.filter(
					(currency) =>
						currency.code.toLowerCase().includes(searchValue) ||
						currency.name.toLowerCase().includes(searchValue)
				);
			}
			if (isLowBalanceHidden) {
				return currencies.filter((currency) =>
					currency?.baseAvailable
						? currency.baseAvailable > minHideValue
						: currency.available > minHideValue
				);
			} else {
				return currencies;
			}
		}
	}

	const visibleMainCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				mainCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[mainCurrencies, isLowBalanceHidden, searchValue]
	);

	const visibleSpotCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				spotCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[spotCurrencies, isLowBalanceHidden, searchValue]
	);
	const visibleMarginCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				marginCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[marginCurrencies, isLowBalanceHidden, searchValue]
	);

	const visibleCommissionCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				commissionCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[commissionCurrencies, isLowBalanceHidden, searchValue]
	);

	useEffect(() => {
		if (!isLowBalanceHidden) {
			if (mainCurrencies) {
				const localIsEmpty =
					(visibleCommissionCurrencies?.length ?? 0) +
						(visibleMainCurrencies?.length ?? 0) +
						(visibleMarginCurrencies?.length ?? 0) +
						(visibleSpotCurrencies?.length ?? 0) ===
					0;
				dispatch(setIsEmpty(localIsEmpty));
			}
		}
	}, [
		visibleCommissionCurrencies,
		visibleMainCurrencies,
		visibleMarginCurrencies,
		visibleSpotCurrencies,
		walletConvertAssets,
	]);

	const loadingCards = ['1', '2', '3', '4'];

	if (isWalletSearchModal && isEmpty) {
		return <WalletEmptyList isWalletSearchModal />;
	}

	if (isWalletConvertModal) {
		return (
			<div className="assetsWalletSearchContainer">
				{!walletConvertAssets
					? loadingCards.map((card) => (
							<WalletItem isLoading key={card} idx={Number(card)} />
					  ))
					: walletConvertAssets?.assets.map((currency, idx: number) => (
							<WalletItem
								isChecked={(selected as boolean[])[idx]}
								handleSelectedAmountChange={handleSelectedAmountChange}
								basePrecision={mainCurrencies?.basePrecision}
								isBalanceHidden={isBalanceHidden}
								baseAsset={globalAsset.code}
								key={idx}
								idx={idx}
								asset={currency}
							/>
					  ))}
			</div>
		);
	}

	if (!isLoading && !isLowBalanceHidden && isEmpty && !isWalletSearchModal) {
		return <WalletEmptyList />;
	}

	return (
		<div
			style={
				isWalletSearchModal
					? {}
					: {
							marginBottom: '64px',
					  }
			}
			className={
				isWalletSearchModal ? 'assetsWalletSearchContainer' : 'assetsContainer'
			}
		>
			{!visibleMainCurrencies
				? loadingCards.map((card) => (
						<WalletItem isLoading key={card} idx={Number(card)} />
				  ))
				: visibleMainCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
				  ))}

			{visibleSpotCurrencies && visibleSpotCurrencies.length > 0 && (
				<>
					<div className="innerAssetsContainer">
						<h1 className="innerAssetsHeaderText">{t('wallet_spot')}</h1>
						<div className="innerAssetsTransferContainer">
							<TransferSpotIcon />
							<h1 className="innerAssetsTransferText">
								{t('wallet_transfer')}
							</h1>
						</div>
					</div>
					{visibleSpotCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
					))}
				</>
			)}

			{visibleMarginCurrencies &&
				visibleMarginCurrencies.length > 0 &&
				!marginCurrencies?.isEmpty && (
					<>
						<div className="innerAssetsContainer">
							<h1 className="innerAssetsHeaderText">
								{t('wallet_leverage')}
							</h1>
							<LeverageIcon />
						</div>
						{visibleMarginCurrencies?.map((currency, idx: number) => (
							<>
								{currency.available > 0 ? (
									<WalletItem
										basePrecision={mainCurrencies?.basePrecision}
										isBalanceHidden={isBalanceHidden}
										baseAsset={globalAsset.code}
										key={idx}
										idx={idx}
										asset={currency}
									/>
								) : (
									''
								)}
							</>
						))}
					</>
				)}

			{visibleCommissionCurrencies && visibleCommissionCurrencies.length > 0 && (
				<>
					<div className="innerAssetsContainer">
						<h1 className="innerAssetsHeaderText">
							{t('wallet_commission')}
						</h1>
					</div>
					{visibleCommissionCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
					))}
				</>
			)}
		</div>
	);
};

interface MarketAssetsListProps {
	isLoading: boolean;
	tab: TMarketTab;
	timeFrame: string;
	isSorted: number;
}

const MarketAssetsList = ({
	isLoading,
	tab,
	timeFrame,
	isSorted,
}: MarketAssetsListProps) => {
	const { t } = useTranslation();
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketAssets = useAppSelector(selectMarketAssets);

	const [marketTabAssets, setMarketTabAssets] = useState<TMarketAsset[]>();

	const dispatch = useAppDispatch();

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	const [percentChange, setPercentChange] =
		useState<keyof TMarketAsset>('percentChange24h');

	useEffect(() => {
		if (timeFrame === `1${t('filters_h')}`) {
			setPercentChange('percentChange1h');
		}
		if (timeFrame === `24${t('filters_h')}`) {
			setPercentChange('percentChange24h');
		}
		if (timeFrame === `7${t('filters_d')}`) {
			setPercentChange('percentChange7d');
		}
		if (timeFrame === `30${t('filters_d')}`) {
			setPercentChange('percentChange30d');
		}
	}, [timeFrame]);

	function filterAssetsByIsSorted(
		assets: TMarketAsset[] | undefined,
		isSorted: number
	) {
		if (assets) {
			if (isSorted === -1) {
				return assets;
			}
			if (isSorted === 1) {
				const localAssets = [...assets];
				return localAssets.sort((firstAsset, secondAsset) => {
					if (firstAsset[percentChange] > secondAsset[percentChange]) {
						return -1;
					} else if (firstAsset[percentChange] < secondAsset[percentChange]) {
						return 1;
					} else if (firstAsset[percentChange] === undefined) {
						return 1;
					} else if (secondAsset[percentChange] === undefined) {
						return -1;
					} else {
						return 0;
					}
				});
			}
			if (isSorted === 0) {
				const localAssets = [...assets];
				return localAssets.sort((firstAsset, secondAsset) => {
					if (firstAsset[percentChange] > secondAsset[percentChange]) {
						return 1;
					} else if (firstAsset[percentChange] < secondAsset[percentChange]) {
						return -1;
					} else if (firstAsset[percentChange] === undefined) {
						return -1;
					} else if (secondAsset[percentChange] === undefined) {
						return 1;
					} else {
						return 0;
					}
				});
			}
		}
	}

	useEffect(() => {
		setMarketTabAssets(filterAssetsByIsSorted(marketAssets[tab?.filter], isSorted));
	}, [marketAssets[tab?.filter], isSorted, timeFrame]);

	const handleFavouriteClick = (asset: TMarketAsset, isNeedToAdd: boolean) => {
		dispatch(setFavouriteAssets({ asset: asset, isNeedToAdd: isNeedToAdd }));
	};

	if (tab?.filter === 'crypto' && marketTabAssets?.length === 0) {
		return (
			<div
				style={{
					marginBottom: '64px',
				}}
				className="marketAssetsContainer"
			>
				<div className="marketAssetsEmptyFavouriteContainer">
					<h1 className="marketAssetsEmptyFavouriteHeader">
						{t('assets_favouriteAssets')}
					</h1>
					<h1 className="marketAssetsEmptyFavouriteBottom">
						{t('assets_favouriteAssetsDesc')}
					</h1>
					<FavouriteMarketAssetsEmptyIcon />
				</div>
			</div>
		);
	}

	return (
		<div
			style={{
				marginBottom: '64px',
			}}
			className="marketAssetsContainer"
		>
			{isLoading || !marketTabAssets
				? loadingCards.map((card) => (
						<MarketAssetItem isLoading key={card} idx={Number(card)} />
				  ))
				: marketTabAssets?.map(
						(asset: TMarketAsset, idx: number) =>
							Boolean(asset.isShowInPrices) && (
								<MarketAssetItem
									timeFrame={timeFrame}
									isFavourite={marketAssets['crypto']?.some(
										(localAsset) => asset.code === localAsset.code
									)}
									globalAssetCode={globalAsset?.code}
									key={asset.code}
									idx={idx}
									asset={asset}
									handleFavouriteClick={handleFavouriteClick}
								/>
							)
				  )}
		</div>
	);
};

interface SearchItemListProps {
	isLoading: boolean;
	searchValue: string;
}

const SearchItemList = ({ isLoading, searchValue }: SearchItemListProps) => {
	const loadingCards = ['1', '2', '3', '4'];

	const { t } = useTranslation();

	const mainRests = useAppSelector(selectMainAccountRests);
	const commssionRests = useAppSelector(selectCommissionAccountRests);
	const spotRests = useAppSelector(selectSpotAccountsRests);
	const marginRests = useAppSelector(selectMarginAccountsRests);
	const marketAssets = useAppSelector(selectMarketAssets);

	const globalAsset = useAppSelector(selectGlobalAsset);
	const basePrecision = useAppSelector(selectBasePrecision);

	const [isEmpty, setIsEmpty] = useState<boolean>(false);

	function filterRestsBySearchValue(
		assets: (TMarketAsset | TAsset)[] | undefined,
		searchValue: string
	) {
		if (assets) {
			if (searchValue?.trim().length === 0 || searchValue === '') {
				return assets;
			} else {
				return assets?.filter(
					(asset) =>
						asset?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
						asset?.code?.toLowerCase()?.includes(searchValue?.toLowerCase())
				);
			}
		}
	}

	const mainCurrencies = useMemo(
		() => filterRestsBySearchValue(mainRests?.assets, searchValue),
		[mainRests, searchValue]
	);

	const spotCurrencies = useMemo(
		() => filterRestsBySearchValue(spotRests?.assets, searchValue),
		[spotRests, searchValue]
	);

	const marginCurrencies = useMemo(
		() => filterRestsBySearchValue(marginRests?.assets, searchValue),
		[marginRests, searchValue]
	);

	const commissionCurrencies = useMemo(
		() => filterRestsBySearchValue(commssionRests?.assets, searchValue),
		[commssionRests, searchValue]
	);

	const marketCurrencies = useMemo(
		() => filterRestsBySearchValue(marketAssets['all'], searchValue),
		[marketAssets, searchValue]
	);

	useEffect(() => {
		setIsEmpty(
			(mainCurrencies?.length ?? 0) +
				(spotCurrencies?.length ?? 0) +
				(marginCurrencies?.length ?? 0) +
				(commissionCurrencies?.length ?? 0) +
				(marketCurrencies?.length ?? 0) ===
				0
		);
	}, [
		mainCurrencies,
		spotCurrencies,
		marginCurrencies,
		commissionCurrencies,
		marketCurrencies,
	]);

	if (isEmpty && !isLoading && searchValue.length > 0) {
		return (
			<div
				style={{
					marginBottom: '64px',
				}}
				className="searchAssetsContainer"
			>
				<WalletEmptyList isWalletSearchModal />
			</div>
		);
	}

	return (
		<div
			style={{
				marginBottom: '64px',
			}}
			className="searchAssetsContainer"
		>
			{isLoading ? (
				<div className="searchAssetsBlock">
					{loadingCards.map((value) => (
						<SearchAssetItem isLoading={true} idx={Number(value)} />
					))}
				</div>
			) : (
				<div className="searchAssetOuterCommonContainer">
					{Number(mainCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">
								{t('wallet_title')}
							</h1>
							<div className="searchAssetsBlock">
								{mainCurrencies?.map((asset, idx) => (
									<SearchAssetItem
										basePrecision={basePrecision}
										idx={idx}
										asset={asset}
										globalAssetCode={globalAsset.code}
									/>
								))}
							</div>
						</div>
					)}
					{Number(spotCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">{t('wallet_spot')}</h1>
							<div className="searchAssetsBlock">
								{spotCurrencies?.map((asset, idx) => (
									<SearchAssetItem
										basePrecision={basePrecision}
										idx={idx}
										asset={asset}
										globalAssetCode={globalAsset.code}
									/>
								))}
							</div>
						</div>
					)}
					{Number(marginCurrencies?.length) > 0 &&
						marginRests?.assets.some((asset) => asset.available > 0) &&
						!marginRests?.isEmpty && (
							<div className="searchAssetOuterBlock">
								<h1 className="searchAssetBlockHeader">
									{t('wallet_leverage')}
								</h1>
								<div className="searchAssetsBlock">
									{marginCurrencies?.map((asset, idx) => (
										<>
											{(asset as TAsset)?.available > 0 && (
												<SearchAssetItem
													basePrecision={basePrecision}
													idx={idx}
													asset={asset}
													globalAssetCode={globalAsset.code}
												/>
											)}
										</>
									))}
								</div>
							</div>
						)}
					{Number(commissionCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">
								{t('wallet_commission')}
							</h1>
							<div className="searchAssetsBlock">
								{commissionCurrencies?.map((asset, idx) => (
									<SearchAssetItem
										basePrecision={basePrecision}
										idx={idx}
										asset={asset}
										globalAssetCode={globalAsset.code}
									/>
								))}
							</div>
						</div>
					)}
					{Number(marketCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">
								{t('assets_title')}
							</h1>
							<div className="searchAssetsBlock">
								{marketCurrencies?.map((asset, idx) => (
									<>
										{(asset as TMarketAsset)?.isShowInPrices != 0 && (
											<SearchAssetItem
												basePrecision={basePrecision}
												idx={idx}
												asset={asset}
												globalAssetCode={globalAsset.code}
											/>
										)}
									</>
								))}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export { WalletItemList, MarketAssetsList, SearchItemList };
