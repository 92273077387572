import { customFetch } from '../../services';
import { setAccountsInfo } from '../../services/wallet';
import { AppDispatch } from '../../store';
import { API_ROUTES } from '../../types/api.routes';
import {
	TAccountRestBody,
	TConvertLowBalancesBody,
	TMarginAccountsRestsBody
} from '../../types/types';
import { mapResponseError } from '../../utils';

export async function fetchUserRests(
	dispatch: AppDispatch,
	accessToken: string
) {
	const accountsInfoData = await customFetch(
		API_ROUTES.WALLET.ACCOUNTS_INFO.URL,
		{
			method: API_ROUTES.WALLET.ACCOUNTS_INFO.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` }
		}
	);

	const jsonAccountsInfoData = await accountsInfoData.json();
	if (accountsInfoData.status != 200) {
		mapResponseError(accountsInfoData, jsonAccountsInfoData, undefined, dispatch);
	}

	dispatch(setAccountsInfo(jsonAccountsInfoData?.accounts));

	return jsonAccountsInfoData;
}

export async function fetchUserRestByAccount({
	navigate,
	accountNumber,
	assetCode,
	accessToken
}: TAccountRestBody) {
	const userRestByAccountData = await customFetch(
		API_ROUTES.WALLET.ACCOUNT_REST.URL +
			'?' +
			new URLSearchParams({
				acc: accountNumber,
				baseAsset: assetCode
			}),
		{
			method: API_ROUTES.WALLET.ACCOUNT_REST.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` }
		}
	);

	const jsonUserRestByAccountData = await userRestByAccountData.json();
	if (userRestByAccountData.status != 200) {
		mapResponseError(
			userRestByAccountData,
			jsonUserRestByAccountData,
			navigate
		);
	}

	return jsonUserRestByAccountData;
}

export async function fetchUserMarginRests({
	navigate,
	assetCode,
	accessToken
}: TMarginAccountsRestsBody) {
	const userMarginRestsData = await customFetch(
		API_ROUTES.WALLET.ACCOUNTS_MARGIN_RESTS.URL +
			'?' +
			new URLSearchParams({
				baseAsset: assetCode
			}),
		{
			method: API_ROUTES.WALLET.ACCOUNTS_MARGIN_RESTS.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` }
		}
	);

	const jsonUserMarginRestsData = await userMarginRestsData.json();
	if (userMarginRestsData.status != 200) {
		mapResponseError(
			userMarginRestsData,
			jsonUserMarginRestsData,
			navigate
		);
	}

	return jsonUserMarginRestsData;
}

export async function fetchConvertLowBalances({
	navigate,
	assets,
	accessToken
}: TConvertLowBalancesBody) {
	const convertLowBalancesData = await customFetch(
		API_ROUTES.WALLET.CONVERT_LOW_BALANCES.URL,
		{
			method: API_ROUTES.WALLET.CONVERT_LOW_BALANCES.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				assets: assets
			})
		}
	);

	const jsonConvertLowBalancesData = await convertLowBalancesData.json();
	if (convertLowBalancesData.status != 200) {
		mapResponseError(
			convertLowBalancesData,
			jsonConvertLowBalancesData,
			navigate
		);
	}

	return jsonConvertLowBalancesData;
}
