export const API_ROUTES = {
	LOGIN: {
		METHOD: 'POST',
		URL: '/api/token/login'
	},
	SEND_OTP: {
		METHOD: 'POST',
		URL: '/api/token/login/twofa/otp/send'
	},
	DICTIONARY: {
		METHOD: 'GET',
		URL: '/api/dictionaries'
	},
	HEALTH: {
		METHOD: 'GET',
		URL: '/api/health'
	},
	SECURITY: {
		NEWKEY: {
			METHOD: 'GET',
			URL: '/api/security/twofa/googleauthenticator/newkey'
		},
		ENABLE_GA: {
			METHOD: 'POST',
			URL: '/api/security/twofa/googleauthenticator/enable'
		}
	},
	MARKET: {
		PRICES: {
			METHOD: 'POST',
			URL: '/api/assets/prices'
		}
	},
	WALLET: {
		ACCOUNTS_INFO: {
			METHOD: 'GET',
			URL: '/api/accounts/info'
		},
		ACCOUNT_REST: {
			METHOD: 'GET',
			URL: '/api/accounts/rests'
		},
		ACCOUNTS_MARGIN_RESTS: {
			METHOD: 'GET',
			URL: '/api/accounts/rests/margin'
		},
		CONVERT_LOW_BALANCES: {
			METHOD: 'POST',
			URL: '/api/operations/exchange/convertlow'
		}
	}
};
