import { customFetch } from '../../services';
import {
	authenticate,
	clearLoginData,
	setIdentityTypes,
	setInputLength,
	setLoginData,
	setSelectedIdentityType,
} from '../../services/auth';
import { setIsResendCodeActive, updateCounter } from '../../services/root';
import { setIsVerified } from '../../services/user';
import { AppDispatch } from '../../store';
import { API_ROUTES } from '../../types/api.routes';
import { ROUTES } from '../../types/routes';
import { TCustomNavigate, TIdentity, TLoginBody } from '../../types/types';
import { mapResponseError } from '../../utils';

export async function fetchLoginOTP(
	identityType: string,
	state: string,
	navigate: TCustomNavigate,
	dispatch?: AppDispatch
) {
	const otpData = await customFetch(API_ROUTES.SEND_OTP.URL, {
		method: API_ROUTES.SEND_OTP.METHOD,
		headers: {},
		body: JSON.stringify({
			identityType: identityType,
			state: state,
		}),
	});

	const jsonOtpData = await otpData.json();
	if (otpData.status != 200) {
		mapResponseError(otpData, jsonOtpData, navigate);
	}

	dispatch && dispatch(setInputLength(jsonOtpData?.length));
	dispatch && dispatch(setIsResendCodeActive(false));
	dispatch && dispatch(updateCounter(Math.floor(jsonOtpData?.resendIn / 1000)));
	dispatch && dispatch(setSelectedIdentityType(identityType));

	return jsonOtpData;
}

export async function fetchLoginWithoutTwoFA(
	{ login, password, isTelegram, dispatch }: TLoginBody,
	navigate: TCustomNavigate
) {
	const loginData = await customFetch(API_ROUTES.LOGIN.URL, {
		method: API_ROUTES.LOGIN.METHOD,
		headers: {},
		body: JSON.stringify({
			login: login,
			password: password,
			source: isTelegram ? 'Telegram' : 'pwa',
		}),
	});

	const jsonLoginData = await loginData.json();
	if (loginData.status != 200) {
		if (loginData.status === 503) {
			navigate(ROUTES.ROOT.INDEX);
		}
		if (loginData.status === 401) {
			if (jsonLoginData.code === 'MULTI_FACTOR_REQUIRED') {
				dispatch &&
					dispatch(
						setLoginData({
							login: login,
							password: password,
							state: jsonLoginData?.data?.state,
						})
					);
				dispatch &&
					dispatch(
						setIdentityTypes(
							jsonLoginData.data.identityTypes.map(
								//eslint-disable-next-line
								(identityType: any) =>
									(identityType = {
										key: identityType.name,
										value: identityType.name,
									})
							)
						)
					);
				if (
					jsonLoginData.data.identityTypes.length === 1 &&
					jsonLoginData.data.identityTypes.find(
						(identity: TIdentity) => identity.name === 'GoogleAuthenticator'
					)
				) {
					navigate(ROUTES.AUTH.TWO_FA);
					throw jsonLoginData;
				}
				if (
					jsonLoginData.data.identityTypes.length === 1 &&
					jsonLoginData.data.identityTypes.find(
						(identity: TIdentity) => identity.name === 'Email'
					)
				) {
					dispatch && dispatch(setSelectedIdentityType('email'));
					await fetchLoginOTP(
						'email',
						jsonLoginData?.data?.state,
						navigate,
						dispatch
					);
					navigate(ROUTES.AUTH.OTP);
					return jsonLoginData;
				}
				if (jsonLoginData.data.identityTypes.length > 1) {
					dispatch &&
						dispatch(
							setIdentityTypes(
								jsonLoginData.data.identityTypes.map(
									//eslint-disable-next-line
									(identityType: any) =>
										(identityType = {
											key: identityType.name,
											value: identityType.name,
										})
								)
							)
						);
					navigate(ROUTES.AUTH.SELECT_TWOFA);
					throw jsonLoginData;
				}
			}
			throw jsonLoginData;
		}
		throw jsonLoginData;
	}

	if (
		loginData.status === 200 &&
		jsonLoginData.additional.twoFAStatus != 'enabled' &&
		jsonLoginData.accessToken
	) {
		sessionStorage.setItem('temporaryAccessToken', jsonLoginData.accessToken);
		dispatch &&
			dispatch(
				setLoginData({
					login: login,
					password: password,
					state: jsonLoginData?.data?.state,
				})
			);
		return jsonLoginData;
	}

	return jsonLoginData;
}

export async function fetchLoginWithTwoFA(
	{
		login,
		twoFA,
		password,
		isTelegram,
		dispatch,
		isEnablingTwoFa,
		secretKey,
	}: TLoginBody,
	navigate: TCustomNavigate
) {
	if (isEnablingTwoFa && secretKey) {
		const enableTwoFaData = await customFetch(API_ROUTES.SECURITY.ENABLE_GA.URL, {
			method: API_ROUTES.SECURITY.ENABLE_GA.METHOD,
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('temporaryAccessToken')}`,
			},
			body: JSON.stringify({
				key: secretKey,
				code: twoFA?.code,
			}),
		});

		const jsonEnableTwoFaData = await enableTwoFaData.json();
		if (enableTwoFaData.status != 200) {
			mapResponseError(enableTwoFaData, jsonEnableTwoFaData, navigate);
		}

		const loginData = await customFetch(API_ROUTES.LOGIN.URL, {
			method: API_ROUTES.LOGIN.METHOD,
			headers: {},
			body: JSON.stringify({
				login: login,
				password: password,
				source: isTelegram ? 'Telegram' : 'pwa',
			}),
		});

		const jsonLoginData = await loginData.json();
		if (loginData.status != 200) {
			if (loginData.status === 503) {
				navigate(ROUTES.ROOT.INDEX);
			}
			if (
				loginData.status === 401 &&
				jsonLoginData.code === 'MULTI_FACTOR_REQUIRED'
			) {
				if (twoFA) {
					twoFA.state = jsonLoginData.data.state;
				}
			}
		}
	}

	const loginData = await customFetch(API_ROUTES.LOGIN.URL, {
		method: API_ROUTES.LOGIN.METHOD,
		headers: {},
		body: JSON.stringify({
			login: login,
			password: password,
			twoFA: twoFA,
			source: isTelegram ? 'Telegram' : 'pwa',
		}),
	});

	const jsonLoginData = await loginData.json();
	if (loginData.status != 200) {
		mapResponseError(loginData, jsonLoginData, navigate);
	}
	localStorage.setItem('userId', jsonLoginData?.userId);

	dispatch && dispatch(setIsVerified(jsonLoginData?.additional?.isVerified));
	dispatch && dispatch(authenticate(jsonLoginData?.accessToken));
	dispatch && dispatch(clearLoginData());

	return jsonLoginData;
}
