import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';

export const selectIsVerified = (state: TRootState) => state.user.isVerified;

interface IRootInitialState {
	isVerified: boolean;
}

const initialState: IRootInitialState = {
	isVerified:
		JSON.parse(
			localStorage.getItem(localStorage.getItem('userId') as string) as string
		)?.isVerified != undefined
			? Boolean(
					JSON.parse(
						localStorage.getItem(
							localStorage.getItem('userId') as string
						) as string
					)?.isVerified
			  )
			: false,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setIsVerified(state, action) {
			const accSettings = localStorage.getItem(
				localStorage.getItem('userId') as string
			);
			if (accSettings) {
				const parsedAccSettings = JSON.parse(accSettings);
				Object.assign(parsedAccSettings, {
					isVerified: action.payload,
				});
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify(parsedAccSettings)
				);
			} else {
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify({
						isVerified: action.payload,
					})
				);
			}
			state.isVerified = action.payload;
		},
	},
});

export const { setIsVerified } = userSlice.actions;
