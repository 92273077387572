import { useEffect, useState } from 'react';
import { PinInput } from '../../common/inputs';
import { CustomButton } from '../../common/buttons';
import { ROUTES } from '../../../types/routes';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { usePinInput } from 'react-pin-input-hook';
import {
	useLoginWithTwoFAMutation,
	useSendOtpCodeMutation,
} from '../../../data/mutations/login/login';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectIdentityTypes, selectLoginData, selectSelectedIdentityType } from '../../../services/auth';
import { APIError } from '../../common/error';
import { mapClassnameForMobile } from '../../../utils';
import {
	reduceCounter,
	selectCounter,
	selectIsResendCodeActive,
	selectIsTelegram,
	setIsResendCodeActive,
	updateCounter,
} from '../../../services/root';

const LoginOTPLayout = () => {
	const isTelegram = useAppSelector(selectIsTelegram);
	const dispatch = useAppDispatch();
	const selectedIdentityType = useAppSelector(selectSelectedIdentityType);
	const loginData = useAppSelector(selectLoginData);
	const counter = useAppSelector(selectCounter);
	const identityTypes = useAppSelector(selectIdentityTypes);
	const isResendCodeActive = useAppSelector(selectIsResendCodeActive);

	const [fakeError, setFakeError] = useState<boolean>(false);

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				login({
					login: loginData?.login as string,
					password: loginData?.password as string,
					twoFA: {
						code: values,
						state: loginData?.state as string,
						identityType: selectedIdentityType,
					},
					isTelegram: isTelegram,
					dispatch: dispatch,
				});
			}
		},
		type: 'numeric',
		placeholder: '',
	});

	const navigate = useCustomNavigate();

	useEffect(() => {
		if(identityTypes.length === 0) {
			navigate(ROUTES.AUTH.LOGIN);
		}
	},[])

	useEffect(() => {
		if (counter == 0) {
			dispatch(setIsResendCodeActive(true));
			dispatch(updateCounter(60));
		}
		const timer = counter > 0 && setInterval(() => dispatch(reduceCounter()), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	const { isError, error, login, isPending } = useLoginWithTwoFAMutation(navigate);

	const { sendOtpCode } = useSendOtpCodeMutation(navigate);

	const { t } = useTranslation();

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	const handleBackClickButton = () => {
		navigate(ROUTES.AUTH.LOGIN);
	};

	const handleResendCode = () => {
		sendOtpCode({
			identityType: selectedIdentityType,
			state: loginData?.state as string,
			navigate,
			dispatch,
		});
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div className={mapClassnameForMobile('walletLayout authMainContainer')}>
					<div className="innerContainer">
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>
						<h1 className={'confirmEmailText'}>
							{t(
								selectedIdentityType.toLocaleLowerCase() === 'email'
									? 'twoFactorAuthMethods_email'
									: 'twoFactorAuthMethods_phone'
							)}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_subtitle') +
								(selectedIdentityType.toLowerCase() === 'email'
									? t('login_email')
									: t('registration_phone'))}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={isPending}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						{isResendCodeActive ? (
							<h1 className={'resendCodeText'} onClick={handleResendCode}>
								{t('general_resendCode')}
							</h1>
						) : (
							<h1 className={'resendCodeText-inactive'}>
								{t('general_resendCodeIn')} {counter}
							</h1>
						)}
						<APIError isError={fakeError} error={error} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginOTPLayout;
