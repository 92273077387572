import { Navigate, Outlet, useLoaderData } from 'react-router-dom';
import { ROUTES } from '../../types/routes';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	selectLocale,
	selectPreviousMainLocation,
	setAppSettings,
	setIsTelegram,
} from '../../services/root';
import { useTelegram } from '../../hooks/useTelegram';
import { useEffect } from 'react';
import { setDictionary } from '../../services/dictionaries';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { selectIsAuthenticated } from '../../services/auth';
import { TGlobalRouteLoaderResponse } from '../../types/types';

const ProtectedRoute = () => {
	const locale = useAppSelector(selectLocale);

	const isAuthenticated = useAppSelector(selectIsAuthenticated);

	if (!isAuthenticated) {
		return <Navigate to={ROUTES.AUTH.INDEX.replace(':locale', locale)} />;
	}

	return <Outlet />;
};

const CommonRoute = () => {
	const dispatch = useAppDispatch();

	const { isTelegram } = useTelegram();

	useEffect(() => {
		dispatch(setIsTelegram(isTelegram));
	}, []);

	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const previousMainLocation = useAppSelector(selectPreviousMainLocation);
	const navigate = useCustomNavigate();

	useEffect(() => {
		if (isAuthenticated) {
			navigate(
				previousMainLocation != '' ? previousMainLocation : ROUTES.WALLET.INDEX
			);
		}
	}, []);

	return <Outlet />;
};

const GlobalRoute = () => {
	const dispatch = useAppDispatch();
	const data = useLoaderData();

	const navigate = useCustomNavigate();

	useEffect(() => {
		dispatch(
			setDictionary(
				Object.assign((data as TGlobalRouteLoaderResponse)?.healthData, {
					dictionaryName: 'health',
				})
			)
		);

		if ((data as TGlobalRouteLoaderResponse)?.healthData?.title) {
			navigate(ROUTES.MAINTAIN.INDEX);
		}

		dispatch(
			setDictionary(
				Object.assign((data as TGlobalRouteLoaderResponse)?.assetIconsData, {
					dictionaryName: 'assetIcons',
				})
			)
		);

		dispatch(
			setDictionary(
				Object.assign(
					(data as TGlobalRouteLoaderResponse)?.depositWithdrawAssetsData,
					{
						dictionaryName: 'depositWithdrawAssets',
					}
				)
			)
		);

		dispatch(
			setDictionary(
				Object.assign((data as TGlobalRouteLoaderResponse)?.marketTabsData, {
					dictionaryName: 'marketTabs',
				})
			)
		);
		dispatch(setAppSettings((data as TGlobalRouteLoaderResponse)?.appSettingsData));
	}, []);

	return <Outlet />;
};

export { ProtectedRoute, CommonRoute, GlobalRoute };
