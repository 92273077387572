import { createSlice } from '@reduxjs/toolkit';
import { TAppSettings, TLocale, TTheme } from '../../types/types';
import { TRootState } from '../../store';

export const selectLocale = (state: TRootState) => state.root.locale;

export const selectTheme = (state: TRootState) => state.root.theme;

export const selectCounter = (state: TRootState) => state.root.counter;

export const selectIsResendCodeActive = (state: TRootState) =>
	state.root.isResendCodeActive;

export const selectIsTelegram = (state: TRootState) => state.root.isTelegram;

export const selectPreviousLocation = (state: TRootState) => state.root.previousLocation;

export const selectPreviousMainLocation = (state: TRootState) =>
	state.root.previousMainLocation;

export const selectAppSettings = (state: TRootState) => state.root.appSettings;

interface IRootInitialState {
	locale: TLocale;
	theme: TTheme;
	counter: number;
	isResendCodeActive: boolean;
	isTelegram: boolean;
	appSettings: TAppSettings | undefined;
	previousLocation: string;
	previousMainLocation: string;
}

const initialState: IRootInitialState = {
	locale: window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en',
	theme: 'light',
	counter: 60,
	isResendCodeActive: false,
	isTelegram: false,
	appSettings: undefined,
	previousLocation: '',
	previousMainLocation: '',
};

export const rootSlice = createSlice({
	name: 'root',
	initialState,
	reducers: {
		setLocale(state, action) {
			state.locale = action.payload;
		},
		setTheme(state, action) {
			state.theme = action.payload;
		},
		reduceCounter(state) {
			state.counter = state.counter - 1;
		},
		updateCounter(state, action) {
			state.counter = action.payload;
		},
		setIsResendCodeActive(state, action) {
			state.isResendCodeActive = action.payload;
		},
		setIsTelegram(state, action) {
			state.isTelegram = action.payload;
		},
		setAppSettings(state, action) {
			state.appSettings = action.payload;
		},
		setPreviousLocation(state, action) {
			state.previousLocation = action.payload;
		},
		setPreviousMainLocation(state, action) {
			state.previousMainLocation = action.payload;
		},
	},
});

export const {
	setLocale,
	setTheme,
	reduceCounter,
	updateCounter,
	setIsResendCodeActive,
	setIsTelegram,
	setAppSettings,
	setPreviousLocation,
	setPreviousMainLocation,
} = rootSlice.actions;
