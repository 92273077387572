import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { CustomButton } from '../common/buttons';
import { MainLogoIcon } from '../common/icons';
import { ROUTES } from '../../types/routes';
import {HeaderLayout} from '../common/header';

const AuthLayout = () => {
	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isSupportIcon
				/>
				<div
					style={{
						zIndex: '1'
					}}
					className='walletLayout authMainContainer'
				>
					<MainLogoIcon />
					<div className='authBottomButtonContainer'>
						<CustomButton
							onClick={() => console.log('WIP')}
							className='startButton noMargin'
							isGrey
							text={t('registration_startRegistration') as string}
						/>
						<CustomButton
							onClick={() => navigate(ROUTES.AUTH.LOGIN)}
							className='startButton noMargin'
							text={t('quickLogin_identify') as string}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuthLayout;
